export const useHelpers = () => {
  async function getCookies (query) {
    let {result} = await useExtension('getCookies', [query])
    return result
  }
  async function extFetch (...args) {
      let {result: response} = await useExtension('apiReq', args)
      try {
          let body = JSON.parse(response.body)
          response.body = body
          return response
      } catch (error) {
          return response
      }
  }
  async function download (media, source) {
    let filePath = await generateFilePath(media)
    if (media.drm) {
      // let {error} = await useExtension('downloadDRM', [media], 'offscreen')
      // if (error) ElNotification({title: 'DRM Error', message: error, type: 'error'})
    } else {
      useExtension('chrome.downloads.download', [{
        url: media.url, 
        filename: filePath
      }])
    }
  }
  async function generateFilePath (media) {
    let {result: {options}} = await useExtension('chrome.storage.local.get', ['options'])
    let filePath = options?.folderStructure
    if (filePath) {
      filePath = filePath.replace(/\{website\}/g, media.website)
      filePath = filePath.replace(/\{mediaType\}/g, media.type)
      filePath = filePath.replace(/\{fileName\}/g, media.fileName)
      filePath = filePath.replace(/\{mediaSource\}/g, media.source)
      // fileName = fileName.replace(/\{username\}/g, source.username ?? 'all_username')
    }
    return filePath
  }
  async function cancelJob (job) {
    if (job.type == 'drm') {
        await useExtension('drmDownloadPool.cancelJob', [job.id], 'offscreen')
    } else {
        await useExtension('downloadPool.cancelJob', [job.id], 'sandbox')
    }
  }
  function getFileNameFromUrl (url) {
    let segments = url.split('/')
    let lastSegment = segments[segments.length - 1]
    return lastSegment.split('?')?.[0]
  }
  async function useExtension (name, args = [], to = 'bg') {
    return request({cmd: 'callFunction', name, args, to})
  }
  function request(data) {
    return new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(getExtensionID(), data, result => {
        if (chrome.runtime.lastError) {
          return reject(chrome.runtime.lastError.message)
        }
        resolve(result)
      })
    })
  }
  function removeKeys(obj, checker) {
      if (typeof obj !== 'object' || obj === null) {
        return obj
      }
    
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          obj[i] = removeKeys(obj[i], checker)
        }
      } else {
        for (let prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            if (checker(prop, obj[prop])) {
              delete obj[prop]
            } else {
              obj[prop] = removeKeys(obj[prop], checker)
            }
          }
        }
      }
    
      return obj;
  }
  function getExtensionID () {
    let extensionId = new URLSearchParams(window.location.search).get('extensionId')
    if (extensionId) {
      document.cookie = 'extensionId=' + extensionId
      return extensionId
    } else {
        let cookies = document.cookie.split('; ').map(cookie => {
          let [name, value] = cookie.split('=')
          return {name, value}
        })
        let extensionIdCookie = cookies.find(cookie => cookie.name == 'extensionId')
        if (extensionIdCookie) return extensionIdCookie.value
    }
  }
  function parseUrl (url) {
    let urlObject = new URL(url)
    let websiteName = urlObject.hostname.replace('www.', '')
    if (websiteName == 'onlyfans.com') {
      let {type, username, postId, chatId} = parseOnlyfansUrl(url)
      return {websiteName, type, username, postId, chatId}
    }
    if (['fansly.com', 'fans.ly'].includes(websiteName)) {
      let {type, username, postId, chatId} = parseFanslyUrl(url)
      return {websiteName, type, username, postId, chatId}
    }
    if (['redgifs.com'].includes(websiteName)) {
      let {type, username, postId, chatId} = parseRedgifsUrl(url)
      return {websiteName, type, username, postId, chatId}
    }
  }
  function parseRedgifsUrl (url) {
    let type = 'post', matches, username, postId, chatId
    let path = new URL(url).pathname.replace(/\/?$/, '')
    if (matches = path.match(/^\/my\/chats\/chat\/(\d+)$/)) {
      type = 'chat'
      chatId = matches[1]
    } else if (matches = path.match(/^\/watch\/([^\/]+)$/)) {
      type = 'post'
      postId = matches[1]
    } else if (matches = path.match(/^\/users\/([^\/]+)$/)) {
      type = 'user'
      username = matches[1]
    }
    return {type, username, postId, chatId}
  }
  function parseOnlyfansUrl (url) {
    let type = 'post', matches, username, postId, chatId
    let path = new URL(url).pathname.replace(/\/?$/, '')
    if (matches = path.match(/^\/my\/chats\/chat\/(\d+)$/)) {
      type = 'chat'
      chatId = matches[1]
    } else if (matches = path.match(/^\/(\d+)\/([^\/?#]+)$/)) {
      type = 'post'
      postId = matches[1]
      username = matches[2]
    } else if (matches = path.match(/^\/([^\/?#]+)$/)) {
      type = 'user'
      username = matches[1]
    }
    return {type, username, postId, chatId}
  }
  function parseFanslyUrl (url) {
    let type = 'post', matches, username, postId, chatId
    let path = new URL(url).pathname.replace(/\/?$/, '')
    if (matches = path.match(/^\/([^\/?#]+)(\/posts)?$/)) {
      type = 'user'
      username = matches[1]
    } else if (matches = path.match(/^\/post\/(\d+)$/)) {
      type = 'post'
      postId = matches[1]
    } else if (matches = path.match(/^\/messages\/(\d+)$/)) {
      type = 'chat'
      chatId = matches[1]
    }
    return {type, username, postId, chatId}
  }
  async function getExtensionInfo () {
    try {
      let {result, error} = await useExtension('getExtensionInfo')
      if (error) throw error
      return result
    } catch (error) {
      console.error(error)
    }
  }
  function waitForExtensionConnection () {
    let resolved = false, retries = 0
    return new Promise((resolve, reject) => {
      let timer = setInterval(async () => {
        if (retries++ >= 4) {
          ElNotification({title: 'Error', message: 'Unable to connect to the extension', type: 'error'})
          resolve()
          return clearInterval(timer)
        }
        let result = await getExtensionInfo()
        
        if (!resolved && result) {
          clearInterval(timer)
          resolved = true
          resolve(result)
        }
      }, 250)
    })
  }
  return {
    useExtension,
    download,
    generateFilePath,
    getFileNameFromUrl,
    removeKeys,
    cancelJob,
    getExtensionID,
    getCookies,
    extFetch,
    parseUrl,
    getExtensionInfo,
    waitForExtensionConnection
  }
}
