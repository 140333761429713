import moment from 'moment'

export const useFilters = () => ({
    formatDate (value, format = 'YYYY-MM-DD') {
        return value ? moment(value).format(format) : ''
    },
    relativeDate (value) {
        return value ? moment(value).fromNow(format) : ''
    },
    formatCurrency (value) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
        })
        return value && formatter.format(value); 
    },
    capitalize (str = ' ') {
        return str[0].toUpperCase() + str.substring(1, str.length)
    }
})